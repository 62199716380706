<template>
    <select
        :value="modelValue === null ? null : modelValue[option_value]"
        @input="fire($event)"
        class="form-select"
        :required="required"
    >
        <template v-if="empty_option">
            <option value="" selected>{{ empty_option }}</option>
        </template>
        <template v-for="option in options" :key="option">
            <option :value="option[option_value]">{{ option[option_label] }}</option>
        </template>
    </select>
</template>

<script>
export default {
    name: 'FormSelectObject',
    props: {
        modelValue: {
            type: Object,
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        empty_option: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue',
        'selected',
    ],
    methods: {
        fire($event) {
            if ($event.target.value === null || $event.target.value === '') {
                this.$emit('update:modelValue', null);
                this.$emit('selected', null);
            } else {
                let new_object = this.options.find(option => option[this.option_value] === parseInt($event.target.value, 10));
                this.$emit('update:modelValue', new_object);
                this.$emit('selected', new_object);
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
