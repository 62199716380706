/**
 * 連携サイトマスタ
 */
import { isBlank } from '@/utilities/typing'
import Department from '@/models/entities/department';
import Channel from '@/models/entities/channel';
import DetailedChannel from '@/models/entities/detailed-channel';

class Website {
    website_id;               // 連携サイトID
    website_name;             // サイト名
    default_department;       // デフォルト事業部
    default_channel;          // デフォルトチャネル
    default_detailed_channel; // デフォルト送信元ページ
    default_status;           // デフォルトステータス
    secret_key;               // APIシークレットキー

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.website_id = properties.website_id;
        this.website_name = properties.website_name;
        this.default_department = new Department(properties.default_department);
        this.default_channel = new Channel(properties.default_channel);
        this.default_detailed_channel = new DetailedChannel(properties.default_detailed_channel);
        this.default_status = properties.default_status;
        this.secret_key = properties.secret_key;
    }
}

export default Website;
